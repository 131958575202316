@font-path: "../fonts";

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("@{font-path}/Montserrat-Regular.eot");
  src: url("@{font-path}/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
  url("@{font-path}/Montserrat-Regular.woff2") format("woff2"),
  url("@{font-path}/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("@{font-path}/Montserrat-Medium.eot");
  src: url("@{font-path}/Montserrat-Medium.eot?#iefix") format('embedded-opentype'),
  url("@{font-path}/Montserrat-Medium.woff2") format("woff2"),
  url("@{font-path}/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("@{font-path}/Montserrat-Bold.eot");
  src: url("@{font-path}/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
  url("@{font-path}/Montserrat-Bold.woff2") format("woff2"),
  url("@{font-path}/Montserrat-Bold.woff") format("woff");
}

.montserrat {
  font-family: 'Montserrat';
}

/** Source Code Pro Start **/

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-Light.eot');
  src: url('@{font-path}/SourceCodePro-Light.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-Light.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-Light.woff') format('woff'),
  url('@{font-path}/SourceCodePro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-Medium.eot');
  src: url('@{font-path}/SourceCodePro-Medium.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-Medium.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-Medium.woff') format('woff'),
  url('@{font-path}/SourceCodePro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-ExtraLightIt.eot');
  src: url('@{font-path}/SourceCodePro-ExtraLightIt.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-ExtraLightIt.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-ExtraLightIt.woff') format('woff'),
  url('@{font-path}/SourceCodePro-ExtraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-Bold.eot');
  src: url('@{font-path}/SourceCodePro-Bold.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-Bold.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-Bold.woff') format('woff'),
  url('@{font-path}/SourceCodePro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-MediumIt.eot');
  src: url('@{font-path}/SourceCodePro-MediumIt.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-MediumIt.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-MediumIt.woff') format('woff'),
  url('@{font-path}/SourceCodePro-MediumIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-ExtraLight.eot');
  src: url('@{font-path}/SourceCodePro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-ExtraLight.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-ExtraLight.woff') format('woff'),
  url('@{font-path}/SourceCodePro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-Regular.eot');
  src: url('@{font-path}/SourceCodePro-Regular.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-Regular.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-Regular.woff') format('woff'),
  url('@{font-path}/SourceCodePro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-Semibold.eot');
  src: url('@{font-path}/SourceCodePro-Semibold.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-Semibold.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-Semibold.woff') format('woff'),
  url('@{font-path}/SourceCodePro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-It.eot');
  src: url('@{font-path}/SourceCodePro-It.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-It.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-It.woff') format('woff'),
  url('@{font-path}/SourceCodePro-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-SemiboldIt.eot');
  src: url('@{font-path}/SourceCodePro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-SemiboldIt.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-SemiboldIt.woff') format('woff'),
  url('@{font-path}/SourceCodePro-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-BoldIt.eot');
  src: url('@{font-path}/SourceCodePro-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-BoldIt.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-BoldIt.woff') format('woff'),
  url('@{font-path}/SourceCodePro-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-Black.eot');
  src: url('@{font-path}/SourceCodePro-Black.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-Black.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-Black.woff') format('woff'),
  url('@{font-path}/SourceCodePro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-LightIt.eot');
  src: url('@{font-path}/SourceCodePro-LightIt.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-LightIt.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-LightIt.woff') format('woff'),
  url('@{font-path}/SourceCodePro-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('@{font-path}/SourceCodePro-BlackIt.eot');
  src: url('@{font-path}/SourceCodePro-BlackIt.eot?#iefix') format('embedded-opentype'),
  url('@{font-path}/SourceCodePro-BlackIt.woff2') format('woff2'),
  url('@{font-path}/SourceCodePro-BlackIt.woff') format('woff'),
  url('@{font-path}/SourceCodePro-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

.source-code-pro {
  font-family: 'Source Code Pro';
}

/** Source Code Pro End **/
