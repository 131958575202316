@import "customVars";

.v-doc {
  a {
    color: @blue;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #ebedf0;
    width: 100%;

    th {
      border-style: solid;
      text-align: left;
      margin-bottom: 20px;
      padding: 14px 16px;
      border-width: 1px 0;
      border-color: #e8e8e8;
    }

    td {
      padding: 14px 16px;
      border-width: 1px 0;
      border-color: #e8e8e8;

      &:first-child {
        font-weight: 500;
        width: 20%;
        color: #003a8c;
      }
    }
  }

  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
  }
}
