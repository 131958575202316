@import "../customVars";

.v-list-wrapper {
  width: 100%;

  .list-item-container {
    position: relative;

    & + .list-item-container {
      margin-top: 16px;
    }

    .list-item {
      width: 100%;
      min-height: 64px;
      box-shadow: @common-shadow;

      &-col {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      /* making 12 columns based layout only inside list */

      .grid-width(@z) {
        @width: round((@z / 12 * 100) - 0.01, 2);
        width: ~"@{width}%";
      }

      @iterations: 12;
      .ant-col-loop (@i) when (@i > 0) {
        .ant-col-xxl-@{i},
        .ant-col-xl-@{i},
        .ant-col-lg-@{i},
        .ant-col-md-@{i},
        .ant-col-sm-@{i},
        .ant-col-xs-@{i} {
          .grid-width(@i)
        }

        .ant-col-loop(@i - 1);
      }
      .ant-col-loop (@iterations);
    }
  }
}
