@import "../customVars";

.v-spin.ant-spin {
  .ant-spin-dot i {
    background-color: @we-green;
  }
}

.v-spin__container {
  display: flex;
  .v-spin {
    margin: auto;
  }
}
