@import "../customVars";
@import "../colors";

.v-date-picker-wrapper {
  position: relative;

  &.open {
    .v-btn-wrapper {
      .ant-btn {
        background-color: @black;
        border-color: @black;
        color: @white;
      }
    }
  }
}

.v-date-picker.ant-calendar-picker {
  position: absolute;
  left: 0;
  top: 37px;

  .ant-calendar-picker-input.ant-input {
    border: none;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;

    .ant-calendar-range-picker-input, .ant-calendar-picker-icon, .ant-calendar-picker-clear, .ant-calendar-range-picker-separator {
      display: none;
    }
  }
}

.ant-calendar-picker-container.v-calendar-picker-container {
  .ant-calendar {
    padding: 0 32px;
    box-shadow: @common-shadow;

    &.ant-calendar-range {
      width: 784px;
      height: 480px;
    }

    .ant-calendar-panel {
      .ant-calendar-range-middle {
        display: none;
      }
    }
    .ant-calendar-range-part {
      width: 336px;

      &.ant-calendar-range-left {
        .ant-calendar-body {
          padding: 0;
        }
      }
      &.ant-calendar-range-right {
        .ant-calendar-body {
          padding: 0;
        }
      }

      .ant-calendar-body {
        border-top: none;
        .ant-calendar-table {
          .ant-calendar-column-header {
            text-transform: uppercase;
            color: #8d8d8d;
          }
          .ant-calendar-cell {
            padding: 0;
            height: 48px;
            line-height: 48px;

            &.ant-calendar-selected-day {
              .ant-calendar-date {
                background-color: @black;
              }
            }

            .ant-calendar-date:hover {
              background: @light-gray;
            }

            &.ant-calendar-today {
              &.ant-calendar-in-range-cell {
                .ant-calendar-date {
                  background-color: transparent;
                }
              }
              .ant-calendar-date {
                font-weight: normal;
                background-color: @blue;
              }
            }
            .ant-calendar-date {
              border: 0;
              margin: 0;
              width: 48px;
              height: 48px;
              line-height: 48px;
            }
            &.ant-calendar-in-range-cell {
              &:before {
                top: 0;
                bottom: 0;
                background-color: @black;
              }
              .ant-calendar-date {
                color: @white;
              }
            }
          }
        }
      }
    }

    .ant-calendar-input-wrap {
      display: none;
    }
    .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn {
      display: none;
    }
    .ant-calendar-prev-month-btn, .ant-calendar-next-month-btn {
      color: @black;
      font-size: 32px;
      line-height: 24px;
    }
    .ant-calendar-prev-month-btn {
      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik0xNC40MTQgMTZsNi4yOTMgNi4yOTNhMSAxIDAgMCAxLTEuNDE0IDEuNDE0bC03LTdhMSAxIDAgMCAxIDAtMS40MTRsNy03YTEgMSAwIDAgMSAxLjQxNCAxLjQxNEwxNC40MTQgMTZ6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTggLTgpIj48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIGZpbGw9IiMzMzMiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48ZyBmaWxsPSIjMEEwNjA2IiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDMydjMySDB6Ii8+PC9nPjwvZz48L3N2Zz4=');
      }
    }
    .ant-calendar-next-month-btn {
      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik0xOS43MDcgMTUuMjkzYTEgMSAwIDAgMSAwIDEuNDE0bC03IDdhMSAxIDAgMCAxLTEuNDE0LTEuNDE0TDE3LjU4NiAxNmwtNi4yOTMtNi4yOTNhMSAxIDAgMSAxIDEuNDE0LTEuNDE0bDcgN3oiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOCAtOCkiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iIzMzMyIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPjxnIGZpbGw9IiMwQTA2MDYiIG1hc2s9InVybCgjYikiPjxwYXRoIGQ9Ik0wIDBoMzJ2MzJIMHoiLz48L2c+PC9nPjwvc3ZnPg==');
      }
    }
    .ant-calendar-month-select, .ant-calendar-year-select {
      line-height: 24px;
    }

    .ant-calendar-header {
      padding-top: 16px;
      padding-bottom: 16px;
      line-height: 24px;
      box-sizing: content-box;
      height: 24px;

      .ant-calendar-prev-month-btn {
        left: 12px;
      }
      .ant-calendar-next-month-btn {
        right: 12px;
      }
    }

    .ant-calendar-footer {
      margin-top: 16px;
      border-top: 1px solid #e0e0e0;
      padding: 0;

      .ant-calendar-footer-extra {
        float: none
      }

      .v-range-picker-extra-footer {
        display: flex;
        justify-content: space-between;
        padding: 15px 0 16px;

        .v-btn-wrapper {
          & + .v-btn-wrapper {
            margin-left: 16px;
          }
        }
        .v-range-picker-reset {
          display: inline-block;
          line-height: 48px;
          color: @blue;
          cursor: pointer;

          &.hidden {
            visibility: hidden;
          }
        }
      }
    }
  }
}
