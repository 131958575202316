@import '../customVars.less';

.ant-input.v-input::placeholder {
  color: @gray;
}

@fast: 150ms ease;
@green: #00A87A;
@bg-color: #EBEFEE;
@bg-color-white: #FCFDFD;
@input-top-basic: 0;
@input-top: 14px;
@label-top: 4px;
@label-font-size: 13px;

.we-input-container {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  background-color: @bg-color;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #525252;
  font-size: 13px;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.02);
  transition: border @fast, box-shadow @fast, border-color @fast;

  &-focused, &:hover {
    border-color: rgba(0,0,0,0.8);
  }

  &-focused {
    background-color: @bg-color-white;
  }

  &-spinner {
    margin-top: 6px;
  }

  .we-input-addon {
    display: inline-block;
    width: 48px;
    height: 48px;
    padding-top: 8px;
    text-align: center;

    &-clear {
      cursor: pointer;
    }

    &-before {
      left: 0;
    }
    &-after {
      position: absolute;
      right: 0;
    }
  }

  .we-input-wrapper {
    display: inline-block;
    position: relative;
    width: calc(100% - 16px);

    &:first-child {
      padding-left: 16px;
    }

    .we-input-label {
      position: absolute;
      height: 32px;
      width: 100%;
      top: @input-top;
      font-family: Montserrat;
      font-size: @label-font-size;
      font-weight: 500;
      color: #525252;
      pointer-events: none;
      transition: top @fast, font-size @fast;
    }

    &:not(.active) {
      .we-input-label {
        background: @bg-color;
      }
    }

    &.active {
      .we-input-label {
        font-size: 11px;
        top: @label-top;
        opacity: 0.7;
      }
    }

    //&::before {
    //  position: absolute;
    //  content: attr(data-text);
    //  top: @input-top;
    //  font-family: Montserrat;
    //  font-size: @label-font-size;
    //  font-weight: 500;
    //  color: #525252;
    //  pointer-events: none;
    //  transition: top @fast, font-size @fast;
    //}
    //&.active {
    //  &::before {
    //    font-size: 11px;
    //    top: @label-top;
    //    opacity: 0.7;
    //  }
    //}

    .we-input {
      width: inherit;
      display: inline-block;
      height: inherit;
      font-family: Montserrat;
      line-height: 16px;
      font-weight: 500;
      border: none;
      background-color: @bg-color;
      color: @black;
      text-overflow: ellipsis;
      filter: none; // For disable Firefox autofill styles

      &:-webkit-autofill, // For disable Webkit autofill styles
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active  {
        box-shadow: 0 0 0 30px @bg-color inset;
      }

      &:focus {
        outline: 0;
        background-color: @bg-color-white;
        box-shadow: 0 0 0 30px @bg-color-white inset!important;
      }
    }

    textarea.we-input {
      padding: 16px 16px 16px 0;
      resize: none;
    }
  }

  &-labeled {
    .we-input {
      padding-top: @input-top!important;
    }
    .we-input-addon-clear {
      position: relative;
      top: 4px;
    }
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
  }

  &-input {
    .we-input-wrapper {
      height: 48px;
    }
  }
}

// Form validations
.ant-form-item-control {
  &.has-error {
    .we-input-container {
      border-color: #F0212B;
    }
    .ant-form-explain {
      background: #FEF5F6;
      border-radius: 2px;
      color: #F0212B!important;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      padding: 4px 8px;
    }
  }

  &.has-success {
    .we-input-container {
      border-color: #00A87A;
    }
  }
}

// DateInput
.we-date-input {
  .we-input-addon-before {
    margin-right: 8px;
  }

  &__addon-before {
    border-right: 2px solid #D1D1D1;
  }
}
