@import "./customVars.less";

.ant-layout {
  background-color: @body-background;
}

.v-layout.v-main-layout {
  margin: 0 auto;
  min-width: 900px;
}
// min-width: 576px
@media (min-width: @screen-sm-min) {
  .v-layout.v-main-layout {
  }
}
// min-width: 768px
@media (min-width: @screen-md-min) {
  .v-layout.v-main-layout {
    max-width: calc(~"100vw - 32px");
  }
  .ant-modal.v-side-panel {
    min-width: calc(~"100vw - 248px");
    max-width: calc(~"100vw - 248px");
  }
}

// min-width: 1300px
@media (min-width: 1300px) {
  .v-layout.v-main-layout {
    max-width: 1268px;
  }
  .ant-modal.v-side-panel {
    min-width: calc(~"50vw + 406px");
    max-width: calc(~"50vw + 406px");
  }
}
