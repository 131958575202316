@import "../customVars";

.v-suggest__selected-option {
  position: absolute;
  z-index: 2;
  right: 50px;
  top: 8px;
  padding: 8px;
  text-align: center;
  font-size: 11px;
  color: @gray
}

.v-search-input-with-options {
  .v-input.ant-input {
    padding-right: 40px;
  }
}
