@import "../customVars";

.v-tx-details__link {
  font-weight: normal;
  color: @we-green;

  span {
    margin-right: 8px;
    text-overflow: ellipsis;
  }

  .v-icon {
    color: @we-green;
  }
}
