@import "../customVars";

.v-steps.ant-steps {

  .ant-steps-item {
    .ant-steps-item-tail {
      padding: 40px 0 8px;
    }

    .ant-steps-item-content {
      min-height: 80px;
      line-height: 43px;

      .ant-steps-item-title {
        font-size: @font-size-base;
        line-height: 16px;
      }
    }

    .ant-steps-item-tail:after {
      background-color: @secondary-color;
    }

    .ant-steps-item-icon {
      width: 32px;
      height: 32px;
      margin-right: 3px;

      .ant-steps-icon {
        font-size: inherit;
      }
    }
  }

  .ant-steps-item-finish, .ant-steps-item-process {
    .ant-steps-item-icon > .ant-steps-icon {
      color: @black;
    }

    .ant-steps-item-content > .ant-steps-item-title {
      color: @black;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon > .ant-steps-icon {
      color: @black04;
    }

    .ant-steps-item-content > .ant-steps-item-title {
      color: @black04;
    }
  }
}

