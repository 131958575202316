@import "../customVars";

@notification-prefix-cls: ~"v-notification";
@notification-width: 393px;
@notification-padding-vertical: 24px;
@notification-padding-horizontal: 24px;
@notification-padding: @notification-padding-vertical @notification-padding-horizontal;
@notification-margin-bottom: 16px;
@close-btn-color: #9d9c9c;

.@{notification-prefix-cls} {
  position: fixed;
  z-index: @zindex-notification;
  width: @notification-width;
  max-width: ~"calc(100vw - 32px)";
  margin-right: 16px;

  &-topLeft,
  &-bottomLeft {
    margin-left: 16px;
    margin-right: 0;

    .@{notification-prefix-cls}-fade-enter.@{notification-prefix-cls}-fade-enter-active,
    .@{notification-prefix-cls}-fade-appear.@{notification-prefix-cls}-fade-appear-active {
      animation-name: NotificationLeftFadeIn;
    }
  }

  &-notice {
    padding: @notification-padding;
    border-radius: 0;
    box-shadow: @common-shadow;
    line-height: 1.5;
    position: relative;
    margin-bottom: @notification-margin-bottom;
    overflow: hidden;

    &-message {
      font-size: @font-size-lg;
      color: @heading-color;
      margin-bottom: 8px;
      line-height: 24px;
      display: inline-block;

      // https://github.com/ant-design/ant-design/issues/5846#issuecomment-296244140
      &-single-line-auto-margin {
        width: ~"calc(@{notification-width} - @{notification-padding-horizontal} * 2 - 24px - 48px - 100%)";
        background-color: transparent;
        pointer-events: none;
        display: block;
        max-width: 4px;
        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
      }
    }

    &-description {
      font-size: @font-size-base;
    }

    &-closable &-message {
      padding-right: 24px;
    }

    &-with-icon &-message {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin-left: 48px;
      margin-bottom: 8px;
    }

    &-with-icon &-description {
      margin-left: 48px;
      margin-right: 24px;
      font-size: 13px;
    }

    &-icon {
      position: absolute;
      margin: -8px 0 0;
      font-size: 32px;
      height: 32px;
      width: 32px;

      &-success {
        color: @success-color;
      }
      &-info {
        color: @black;
      }
      &-warning {
        color: @warning-color;
      }
      &-error {
        color: @error-color;
      }
    }

    &-close-x:after {
      font-size: @font-size-base;
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16"><defs><path id="a" d="M7 5.589L12.293.298a1 1 0 0 1 1.414 1.414l-5.292 5.29 5.292 5.29a1 1 0 0 1-1.414 1.415L7 8.417l-5.293 5.29a1 1 0 0 1-1.414-1.414l5.292-5.29-5.292-5.29A1 1 0 1 1 1.707.297L7 5.588z"/></defs><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><use fill="@{close-btn-color}" fill-rule="nonzero" xlink:href="#a"/><g fill="@{close-btn-color}" mask="url(#b)"><path d="M-9-9h32v32H-9z"/></g></g></svg>');
      cursor: pointer;
    }

    &-close {
      position: absolute;
      right: 25px;
      top: 25px;
      color: rgba(10, 6, 6, .4);
      outline: none;

      a&:focus {
        text-decoration: none;
      }

      &:hover {
        color: shade(@text-color-secondary, 40%);
      }
    }

    &-btn {
      float: left;
      margin-top: 16px;
      margin-left: 48px;

      .v-btn-wrapper {
        &+.v-btn-wrapper {
          margin-left: 16px;
        }
      }
    }
  }

  &-no-message {
    .@{notification-prefix-cls}-notice-message {
      display: none;
    }
    .@{notification-prefix-cls}-notice-description {
      margin-top: -5px;
    }
  }

  .notification-fade-effect {
    animation-duration: 0.24s;
    animation-fill-mode: both;
    animation-timing-function: @ease-in-out;
  }

  &-fade-enter,
  &-fade-appear {
    opacity: 0;
    .notification-fade-effect();
    animation-play-state: paused;
  }

  &-fade-leave {
    .notification-fade-effect();
    animation-duration: 0.2s;
    animation-play-state: paused;
  }

  &-fade-enter&-fade-enter-active,
  &-fade-appear&-fade-appear-active {
    animation-name: NotificationFadeIn;
    animation-play-state: running;
  }

  &-fade-leave&-fade-leave-active {
    animation-name: NotificationFadeOut;
    animation-play-state: running;
  }
}

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    left: @notification-width;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    opacity: 0;
    right: @notification-width;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeOut {
  0% {
    opacity: 1;
    margin-bottom: @notification-margin-bottom;
    padding-top: @notification-padding;
    padding-bottom: @notification-padding;
    max-height: 150px;
  }
  100% {
    opacity: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }
}
