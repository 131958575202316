@import "./customVars";

.bg-secondary {
  background-color: @secondary-color;
}

.bg-white {
  background-color: @white;
}

.bg-black {
  background-color: @black;
}

.bg-blue {
  background-color: @blue;
}

.bg-marine {
  background-color: @marine;
}

.bg-gray {
  background-color: @gray;
}

.bg-green, .bg-success {
  background-color: @green;
}

.bg-orange, .bg-warning {
  background-color: @orange;
}

.bg-red, .bg-error {
  background-color: @red;
}
