@import "../customVars";

.v-menu-wrapper {
  height: 100%;
  display: flex;

  .v-menu-left-side {
    height: 100%;
    width: 78px;
    float: left;
    margin-right: 1px;

    .v-selected-menu-line {
      width: 100%;
      border-bottom: 2px solid @we-green;
      transition: transform 700ms;
    }
  }

  .ant-menu {
    &:not(.ant-menu-horizontal) {
      float: left;
      width: 208px;

      .ant-menu-item {
        font-weight: 500;
        padding-right: 0;
        color: @black04;

        & > a {
          color: @black04;
        }

        &:not(.ant-menu-item-selected) > a:hover {
          color: @black04;
        }

        &:active {
          background-color: @white;
        }

        &:hover {
          color: @black04;
        }

        .v-icon {
          font-size: 16px;
          padding-right: 24px;
          color: @black04;
        }
      }

      .ant-menu-item.ant-menu-item-active {
        color: @black04;
      }

      .ant-menu-item.ant-menu-item-selected {
        background-color: #fff;
        color: @black;

        & > a {
          color: @black;
        }

        .v-icon {
          color: @black;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
