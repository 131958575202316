.v-suggest.v-context-menu {
  .v-context-menu-content {
    width: 100%;
    box-sizing: content-box;
  }

  .v-suggest-empty {
    padding: 15px 10px 8px;
  }

  .v-btn-wrapper {
    .v-btn-selected {
      background-color: rgba(141, 141, 141, .4);
    }
  }
}
