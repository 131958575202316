@import "../customVars";

.v-select-tags {
  width: 100%;
  .ant-select-selection--multiple {
    min-height: 48px;
    background: #FCFDFD;
    border: 1px solid #0A0606!important;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: none;

    .ant-select-selection__rendered {
      margin-top: 8px;
      margin-left: 16px;
    }

    li.ant-select-selection__choice {
      margin-bottom: 8px;
      padding: 0 20px 0 4px;
      background: rgba(224, 224, 224, 0.5);
      border-radius: 2px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .ant-select-selection__choice__content {
      color: @black;
      font-weight: 500;
    }

    .ant-select-selection__choice__remove {
      margin-top: -4px;
      margin-right: -4px;
      color: #939292;

      svg {
        color: #939292;
      }
    }
  }

  &__not_found {
    color: @gray;
  }
}

.v-select-tags__dropdown {
  .ant-select-dropdown-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2px 8px 8px;
  }
  .ant-select-dropdown-menu-item:not(.v-dropdown-item-inactive) {
    padding: 4px!important;
    margin-top: 8px;
    background: rgba(224, 224, 224, 0.5);
    border-radius: 2px;
    min-width: 16px;
    height: 24px;
    font-size: 13px;
    line-height: 16px;
    color: @black;
    font-weight: 500;
    &:not(:last-child) {
      margin-right: 16px;
    }

    i.anticon {
      display: none!important;
    }
  }

  .ant-select-dropdown-menu-item.v-dropdown-item-inactive {
    opacity: 0.4;
  }

  .ant-select-dropdown-menu-item-selected {
    display: none;
  }
}

.v-select-tags-closed {
  min-height: 56px;
  background: #EBEFEE;
  border-radius: 2px;
  padding: 8px;
  cursor: pointer;

  &__label {
    font-size: 11px;
    font-weight: 500;
    color: #525252;
  }

  &__value {
    margin-top: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}
