// palette page
.color-block {
  .color-square-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .color-square {
      width: 64px;
      height: 64px;
    }
  }
  .color-code {
    width: 100%;
    text-align: center;
    line-height: 16px;
    font-size: 13px;
    margin-top: 8px;
    white-space: nowrap;
  }
  .color-title {
    width: 100%;
    text-align: center;
    font-size: 11px;
    color: #8d8d8d;
    margin-top: 4px;
  }
}

.v-notification-notice-close-x:after {
  content: '' !important;
}
.v-notification-notice-close-x {
  position: absolute;
  display: block;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBmaWxsPSIjMEEwNjA2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiAxNC41ODlsNS4yOTMtNS4yOTFhMSAxIDAgMCAxIDEuNDE0IDEuNDE0bC01LjI5MiA1LjI5IDUuMjkyIDUuMjlhMSAxIDAgMCAxLTEuNDE0IDEuNDE1TDE2IDE3LjQxN2wtNS4yOTMgNS4yOWExIDEgMCAwIDEtMS40MTQtMS40MTRsNS4yOTItNS4yOS01LjI5Mi01LjI5YTEgMSAwIDEgMSAxLjQxNC0xLjQxNUwxNiAxNC41ODh6Ii8+PC9zdmc+');
  width: 32px;
  height: 32px;
  right: -8px;
  top: -10px;
  opacity: 0.4;
}

::selection {
  background: #00A884;
  color: white;
}
