@import "../customVars";

.v-collapse {
  border: 0;
  background: @white;
  
  .ant-collapse-item {
    padding: 18px 0;
    border-bottom-width: 1px;
    border-color: #e0e0e0;
    .arrow.v-icon {
      transition: all 200ms ease-in-out;
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -o-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      transform: rotate(0deg);
      top: 8px;
    }
    > .ant-collapse-header {
      padding: 13px 0 13px 48px;
      font-size: 13px;
      font-weight: 500;
    }
    .ant-collapse-content {
      border-top: 0;
      .ant-collapse-content-box {
        padding: 0 0 0 48px;
      }
    }
  }
  .ant-collapse-item:last-child {
    border-bottom-width: 0;
  }
  .ant-collapse-item-active {
    > .ant-collapse-header  .arrow.v-icon {
      transform: rotate(90deg);
    }
  }

  .ant-collapse-item
}
