@import "../customVars";

.v-layout.ant-layout {
  background-color: @white;

  &.v-main-layout {
    min-height: 100vh;
  }

  .ant-layout-sider {
    width: 286px;
    background-color: @white;
  }

  .ant-layout-footer {
    background-color: @white;
  }
}
