@import "../customVars";

.v-btn-wrapper {
  display: inline-block;

  &.spinning {
    .v-icon {
      visibility: hidden;
    }

    button > span {
      visibility: hidden;
    }

    .v-spin {
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
    }
  }

  .ant-btn {
    font-weight: 500;
    padding: 0 18px;
    box-shadow: none;
    color: @green-basic;
    border-width: 1px;
    border-color: rgba(0, 128, 94, 0.2);
    border-radius: 2px;
    transition: border 120ms linear;
    cursor: pointer;

    &.ant.btn-clicked {
      transition: none;
    }

    &.v-btn-mini {
      width: 32px;
      height: 32px;
      padding: 0;
      color: @gray;
      box-shadow: none;

      &:focus:active, &:active, &.ant-popover-open {
        // fix safari behavior
        color: @green-black-dark !important;
        background-color: @white;
        border-color: @white;
      }

      &:focus, &:hover {
        background-color: @white;
        border-color: @white;
        color: @gray;
      }
    }

    .v-icon {
      &+ span {
        margin-left: 9px;
      }
    }

    &:not(.ant-btn-circle) {
      &:not(.ant-btn-circle-outline){
        &.ant-btn-icon-only {
          padding: 0 16px;
        }
      }
    }

    &:disabled {
      font-weight: 500;
      background-color: #d1d1d1 !important;
      border-color: transparent !important;
      color: rgba(10, 6, 6, .4) !important;
      box-shadow: none!important;
      cursor: default;
    }


    &:focus, &:hover {
      background-color: @white;
      border-color: @green-basic;
    }

    &:focus:active, &:active, &.ant-popover-open {
      background-color: @green-black-dark;
      border-color: @green-black-dark;
      color: @white;
    }

    &.ant-btn-primary {
      color: @white;
      border-width: 1px;
      box-shadow: 0 4px 12px rgba(0, 168, 122, 0.3);

      &:focus, &:hover {
        background-color: @we-green;
        border-color: @black;
      }

      &:hover {
        border-color: @green-black-dark;
      }

      &:focus:active, &:active , &.ant-popover-open {
        background-color: @green-black-dark;
        border-color: @green-black-dark;
        color: @white;
      }

      &:active {
        box-shadow: none;
      }
    }
  }
  // remove ant design animation
  .ant-btn-clicked {
    &:after {
      animation: none;
    }
  }
}

.v-inline-button {
  display:inline-block;
  color: @green-basic;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 120ms linear;

  > div {
    display: inline-block;
  }

  > div:first-child {
    margin-right: 8px;
    padding: 8px 0 8px 8px;
  }

  &:hover {
    background: rgba(224, 224, 224, 0.5);
  }
}
