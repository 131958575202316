@import "../customVars";

.v-input-number-or-range {
  display: inline-block;
}

.v-input-number-or-range-tooltip {
  .ant-popover-inner, .ant-popover-inner-content {
    padding: 0 !important;
  }
}
