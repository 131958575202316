@import "../customVars";

.v-suggest-input {
  font-weight: 500;
  position: relative;
}

.v-suggest-options {
  background-color: white;
  color: @black;
  width: 100%;
  margin-top: -1px;
  min-width: 120px;
  padding: 8px;
  box-shadow: 0 8px 12px 0 rgba(10, 6, 6, 0.1);
  overflow-y: auto;
  max-height: 258px;
  border: 1px solid #0A0606;
  box-sizing: border-box;
  border-radius: 2px;

  .v-suggest-options__list-item {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .v-suggest-options__list-item {
    cursor: pointer;

    &:hover {
      background-color: #D1D1D1;
      color: @black;
    }
  }

  .v-suggest-options__list-item-active {
    .v-suggest-options__list-item;
    background-color: @we-green;
    color: @white;
  }

  .v-suggest-options__list-item-value {
    padding-left: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }

  .v-input-spinner {
    top: 7px !important;
    right: 14px !important;
  }
}
