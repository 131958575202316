@import "../customVars";

.v-tx-details__li {
  position: relative;
  overflow: hidden;
  padding: @row-height 0;
  border-bottom: 1px solid @secondary-color;
  margin: 0;

  .v-search_highlighted {
    background: @green;
    padding: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  p {
    margin: 0;
  }

  &-key {
    word-break: break-word;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.23;
    color: #525252;
  }

  &-value {
    margin: 0;
    line-height: 1.23;
    color: @black;
  }

  .v-tx-details__link {
    float: right;
    margin-top: 12px;
  }
}

.v-tx-details__li.single {
  display: flex;
  justify-content: space-between;
  height: 49px;
  white-space: nowrap;

  .v-tx-details__li-key {
    font-size: 13px;
  }

  .v-tx-details__li-value {
    font-weight: 500;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:last-child, &:first-child {
    height: 33px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.v-tx-details__li.multiple {
  text-overflow: ellipsis;
  height: auto;

  > div {
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.v-tx-details__li.number {
  display: flex;
  justify-content: space-between;
  .v-tx-details__li-key {
    font-size: 13px;
  }
  .v-tx-details__li-value {
    font-family: @font-family-mono;
    font-size: 13px;
    font-weight: 500;
    color: @black;
    &-hidden {
      visibility: hidden;
    }
    &-group:not(:first-child)::before {
      content: "\2009";
      width: 5px;
      display: inline-block;
    }
  }
}

.v-tx-details__li.multiple, .v-tx-details__li.link {
  .v-tx-details__li-value {
    margin-top: 8px;
  }
}

.v-tx-details__li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
