@import "../customVars";

.v-badge.ant-badge {
  .ant-badge-count {
    background-color: @red;
    max-width: 24px;
    width: 24px;
    border-radius: 50%;
    height: 24px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    border: 0;
    box-shadow: none;
  }
}
