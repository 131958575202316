@import "../customVars";

.v-select {
  box-shadow: @common-shadow;
  border: 0;
  background: @white;
  &:active {
    background: @black;
    span, svg {
      color: @white;
    }
  }
  .ant-select-selection {
    height: auto;
    padding: 14px 8px;
    max-height: 48px;
    border: 0;
    background: none;

    &:hover, &:focus, &:active {
      box-shadow: none;
    }

    .ant-select-selection__rendered {
      line-height: 16px;
    }
  }

  .ant-select-selection-selected-value {
    display: flex !important;
    padding: 0;
    align-items: center;
    .v-icon {
      top: -8px;
    }
    .v-icon + span {
      position: relative;
      top: -8px;
    }
  }

  .ant-select-arrow {
    display: none;
  }

  &__dropdown {
    li {
      padding: 10px;
    }
    li.ant-select-dropdown-menu-item-active {
      background: @light-gray;
    }
  }
}

.v-select-wrapper {
  position: relative;
}

.ant-select-open {
  background: @black;
  span, svg {
    color: @white;
  }
}

.v-select__dropdown.ant-select-dropdown {
  box-shadow: @common-shadow;
}