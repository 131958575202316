@import "../customVars";

.v-tabs.ant-tabs {
  overflow: visible;

  .ant-tabs-nav {
    color: @black04;
    font-weight: 500;

    .ant-tabs-tab {
      padding: 17px 0 15px;
    }

    .ant-tabs-tab-active, .ant-tabs-tab:hover {
      color: @black;
    }

    .ant-tabs-ink-bar {
      background-color: @we-green;
    }
  }

  &:not(.ant-tabs-vertical) {
    & > .ant-tabs-content-animated {
      transition: none;
    }

    & > .ant-tabs-content > .ant-tabs-tabpane {
      transition: none
    }
  }

  .ant-tabs-bar {
    border-bottom: none;
  }
}

.v-tabs.ant-tabs-card {
  .ant-tabs-nav-container {
    height: 48px!important;
  }

  .ant-tabs-nav {
    display: flex;
    justify-content: center;

    > div:first-child {
      border-radius: 4px;
      box-shadow: inset 0 0 0 2px rgba(0,128,94, 0.2);
      background: white;

      .ant-tabs-tab {
        height: 48px;
        min-width: 124px;
        text-align: center;
        line-height: 48px;
        margin-right: 0!important;
        padding: 0 16px;
        border-radius: 4px;
        background-color: unset;
        color: #525252;
        border: none;
        transition-duration: 0.15s;
        user-select: none;

        &-active {
          background-color: @we-green;
          color: #FFFFFF;
          border-color: @we-green;
        }
      }
    }
  }
}
