@import "../customVars";

.v-single-bar {
  &__bar {
    width: 100%;
    height: 24px;
    box-shadow: @common-shadow;
  }
}

.v-legend {
  margin-top: 24px;

  .ant-col-5 {
    width: 33.33%;
  }

  &__row {
    margin-top: 24px;
  }

  &__header {
    line-height: 16px;
  }

  &__point {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    float: left;
    margin-right: 8px;
  }

  &__name {
    font-size: 11px;
    font-weight: 500;
    color: #8d8d8d;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 8px;
  }

  &__body {
    font-size: 32px;
    color: #0a0606;
    opacity: 0.8;
    font-weight: bold;
  }
}
