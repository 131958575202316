@import "../customVars";

.v-radio-group.ant-radio-group {
  .v-radio-wrapper {
    .ant-radio {
      &+ span {
        padding-right: 2px;
        color: @black;
      }
    }
  }
}

.v-radio-wrapper {
  display: block;
  padding: 14px 0;
  margin-bottom: 1px;
  margin-right: 0;

  &.ant-radio-wrapper-disabled {
    .ant-radio {
      .ant-radio-inner {
        border-color: @gray !important;
        background-color: @gray !important;
        opacity: 0.4;
      }
      &+ span {
        color: @black;
      }
    }
  }

  .ant-radio {
    &.ant-radio-checked::after {
      // animation
      animation: none;
    }
  }

  .ant-radio-inner {
    border: @border-width-base solid #333333;

    &:after {
      border-radius: 50%;
    }
  }

  .ant-radio-checked {
    &:after {
      border-color: @we-green;
    }
    .ant-radio-inner {
      background-color: @we-green;
      border: @border-width-base solid @we-green;
      &:after {
        @radio-dot-size: @radio-size - 8px;
        left: (@radio-size - @radio-dot-size) / 2 - 2px;
        top: (@radio-size - @radio-dot-size) / 2 - 2px;
        background-color: #fff;
      }
    }
  }

  &:hover {
    .ant-radio-inner {
      border-color: @we-green!important;
    }
  }
}
