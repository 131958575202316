@import "../customVars";

.v-single-checkbox.ant-checkbox-wrapper {
  padding: 16px 0;
  font-size: 13px;
  line-height: 16px;
  height: 48px;

  .ant-checkbox {
    margin-top: -2px;

    .ant-checkbox-inner {
      border-color: @primary-color;
      &:after {
        top: -1px;
        left: 2.5px;
        width: 6px;
        height: 12px;
        // animation
        transition: none;
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: @we-green;
        background-color: @we-green;
      }
    }

    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        border-color: @we-green;
        &:after {
          background-color: @we-green;
          left: 6px;
          top: 6px;
          width: 13px;
          height: 13px;
          border: 2px solid @white;
          // animation
          transition: none;
        }
      }
    }
    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: @gray !important;
        background-color: @gray !important;
        opacity: 0.4;
      }
      &+ span {
        color: @black;
      }
    }
    // animation
    &.ant-checkbox-checked::after {
      animation: none;
    }
  }
}

.ant-checkbox-group.v-checkbox-group {
  display: inline-block;
  padding-left: 16px;

  .ant-checkbox {
    margin-top: -2px;
    &.ant-checkbox-checked::after {
      animation: none;
    }
    .ant-checkbox-inner {
      border-color: @primary-color;
      &:after {
        top: -1px;
        left: 2.5px;
        width: 6px;
        height: 12px;
        // animation
        transition: none;
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: @we-green;
        background-color: @we-green;
      }
    }
  }
  .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding: 16px 0;
    line-height: 16px;
    height: 48px;
    font-size: 13px;

    &:last-child {
      margin-right: 0;
    }

    .ant-checkbox {
      &+ span {
        padding-right: 2px;
      }
    }
  }

}
