@import '../customVars';

.v-file-upload {
  display: flex;
  .ant-form-item-control.has-error {
    .v-file-upload__drag_area {
      .ant-upload {
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px @red;
      }
    }
  }

  .v-uploadList {
    margin-left: 32px;
    width: 320px;
    .v-uploadList__item {
      .v-uploadList__data, .v-uploadList__delete {
        display: inline-block;
      }

      .v-uploadList__delete {
        margin-left: 10px;
        opacity: 0.4;
        cursor: pointer;
      }

      .v-uploadList__data {
        max-width: calc(100% - 40px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 16px;
        vertical-align: text-top;
        color: @we-green;
      }

      &-unacceptable {
        .v-uploadList__data, .v-uploadList__delete  {
          color: @red
        }
        .v-uploadList__delete {
          opacity: 1;
        }
      }
    }
  }

  .v-file-upload__drag_area {
    .ant-upload {
      width: 448px;
      height: 128px;
    }
    .ant-upload.ant-upload-drag {
      border: none;
      background: @white;
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);

      .ant-upload-drag-container {
        vertical-align: unset;
        display: block;
      }
    }

    .ant-upload.ant-upload-drag.ant-upload-drag-hover {
      border: none;
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px @marine;
    }

    .ant-upload-list {
      .ant-upload-list-item-progress {
        padding-left: unset;
      }

      .ant-upload-list-item {
        .anticon.anticon-close {
          opacity: 1;
        }
        .ant-upload-list-item-info {
          padding-right: 0;

          .anticon.anticon-paper-clip {
            display: none;
          }
          .ant-upload-list-item-name {
            padding-left: unset;
          }
        }
      }

      .ant-upload-list-item:hover .ant-upload-list-item-info{
        background-color: unset;
      }
    }

    .v-upload-inner {
      user-select: none;
      .v-upload-icon {
        color: @black;
      }
      .v-upload-text {
        position: absolute;
        width: 352px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 32px;
        line-height: 16px;
        color: @black;
        font-weight: 500;
      }
    }
  }
}
