.v-icon {
  position: relative;
  display: inline-block;
  top: -1px;
  vertical-align: baseline;
  margin-left: -8px;
  width: 32px;
  height: 32px;
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: -8px;
    height: 32px;
    width: 32px;
  }
}

.v-tx__list-item {
  .v-icon {
    margin-left: 0;
  }
}

.ant-modal-title {
  .v-icon-wrapper {
    > .v-icon {
      top: 5px;
      svg {
        width: 1em !important;
        height: 1em !important;
      }
    }
  }
}

.list-item-col {
  .v-icon {
    margin-left: 18px;
  }
}

.v-tx-row__common__icon {
  padding-top: 16px;

  &__wrapper {
    padding-left: 18px;
  }
  .v-icon {
    top: 2px;
    margin-left: 0;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

i.v-notification-notice-icon {
  margin-top: 18px;
}

.icon-16x16-fix {
  margin: 0 -8px 0 0;
  svg {
    width: 16px;
    height: 16px;
  }
}