@import '../customVars';

.v-tx-list__data-col {
  p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__value {
    font-size: 13px;
    color: @black;
  }

  &__caption {
    color: @gray;
    font-size: 11px;
  }
}
