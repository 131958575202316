@import "../customVars";

.v-context-menu {
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: @common-shadow;
    margin-top: -4px;
    padding: 0;

    .v-context-menu-title {
      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      padding: 16px 8px 8px;
      height: 40px;
      color: @gray;
      min-width: 177px;
      margin: 0;
      min-height: 32px;
      border-bottom: 1px solid #e0e0e0;
    }

    .v-context-menu-content {
      display: inline-block;
      padding: 10px 28px;

      .v-btn-wrapper {
        display: block;
        width: 100%;
        margin: 0;
        min-width: 194px;

        .ant-btn {
          display: block;
          width: 100%;
          box-shadow: none;
          text-align: left;
          border: none;
          color: @black;
        }

        .ant-btn:hover {
          background: @light-gray;
        }
      }
    }

    .v-context-menu-footer {
      display: flex;
      justify-content: space-between;
      padding: 15px 28px;
      border-top: 1px solid #e0e0e0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.ant-popover.v-context-menu.ant-popover-placement-bottomLeft {
  padding-top: 0;
}

.v-button-context-menu.v-context-menu.ant-popover {
  .v-context-menu-content {
    padding: 8px;
  }
}
