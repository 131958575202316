.we-select-input {
  cursor: pointer;
  .we-input-wrapper.active {
    width: 100%;

    input {
      cursor: pointer;
    }
  }
  .we-input-addon-after {
    pointer-events: none;
    padding-top: 4px;
  }

  &__addon {
    padding-top: 10px;

    svg {
      width: 24px;
      color: black;
      height: 24px;
      transition: transform 0.5s;
    }

    &-opened {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
