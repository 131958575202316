@import "../colors";

.v-context-menu__buttons-block {
  .v-btn-wrapper {
    button:hover {
      background-color: @gray;
    }
  }

  &__divider {
    margin: 8px 0;
  }
}
