@import "../customVars";
@import "../colors";

.v-picker-wrapper {
  position: relative;

  &.open {
    .v-btn-wrapper {
      .ant-btn {
        background-color: @black;
        border-color: @black;
        color: @white;
      }
    }
  }
}

.v-picker.ant-calendar-picker {
  visibility: hidden;
}

.v-picker {
  .ant-calendar-input-wrap {
    display: none;
  }

  .ant-calendar {
    border: 0px;
    box-shadow: none;
  }

  .ant-calendar-header {
    height: 64px;
    line-height: 64px;
    padding: 0px;
    border-bottom: 0px;

    .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn {
      display: none;
    }

    .ant-calendar-prev-century-btn, .ant-calendar-next-century-btn,
    .ant-calendar-prev-decade-btn, .ant-calendar-next-decade-btn,
    .ant-calendar-prev-month-btn, .ant-calendar-next-month-btn {
      line-height: 64px;
      font-size: 32px;
      color: @black;
    }

    .ant-calendar-prev-month-btn {
      left: 12px;
    }

    .ant-calendar-next-month-btn {
      right: 12px;
    }

    .ant-calendar-prev-century-btn, .ant-calendar-prev-decade-btn, .ant-calendar-prev-year-btn {
      left: 12px;
    }

    .ant-calendar-next-century-btn, .ant-calendar-next-decade-btn, .ant-calendar-next-year-btn {
      right: 12px;
    }

    .ant-calendar-my-select {
      line-height: 70px;
    }
  }

  .ant-calendar-month-panel-header {
    height: 64px;
    line-height: 64px;
    padding: 0px;
    border-bottom: 0px;

    .ant-calendar-month-panel-prev-century-btn, .ant-calendar-month-panel-prev-decade-btn,
    .ant-calendar-month-panel-prev-year-btn {
      left: 44px
    }

    .ant-calendar-month-panel-next-century-btn, .ant-calendar-month-panel-next-decade-btn,
    .ant-calendar-month-panel-next-year-btn {
      right: 44px;
    }

    .ant-calendar-month-panel-prev-century-btn, .ant-calendar-month-panel-prev-decade-btn,
    .ant-calendar-month-panel-prev-year-btn, .ant-calendar-month-panel-next-century-btn,
    .ant-calendar-month-panel-next-decade-btn, .ant-calendar-month-panel-next-year-btn {
      line-height: 64px;
      font-size: 24px;
      color: #0a0606;
    }

    .ant-calendar-month-panel-year-select {
      line-height: 70px;
    }
  }

  .ant-calendar-year-panel-header {
    height: 64px;
    line-height: 64px;
    padding: 0px;
    border-bottom: 0px;

    .ant-calendar-year-panel-prev-century-btn, .ant-calendar-year-panel-prev-decade-btn,
    .ant-calendar-year-panel-prev-year-btn {
      left: 44px
    }

    .ant-calendar-year-panel-next-century-btn, .ant-calendar-year-panel-next-decade-btn,
    .ant-calendar-year-panel-next-year-btn {
      right: 44px;
    }

    .ant-calendar-year-panel-prev-century-btn, .ant-calendar-year-panel-prev-decade-btn,
    .ant-calendar-year-panel-prev-year-btn, .ant-calendar-year-panel-next-century-btn,
    .ant-calendar-year-panel-next-decade-btn, .ant-calendar-year-panel-next-year-btn {
      line-height: 64px;
      font-size: 24px;
      color: #0a0606;
    }

    .ant-calendar-year-panel-decade-select {
      line-height: 70px;
    }
  }

  .ant-calendar-decade-panel-header {
    height: 64px;
    line-height: 64px;
    padding: 0px;
    border-bottom: 0px;

    .ant-calendar-decade-panel-prev-century-btn, .ant-calendar-decade-panel-prev-decade-btn,
    .ant-calendar-decade-panel-prev-year-btn {
      left: 44px
    }

    .ant-calendar-decade-panel-next-century-btn, .ant-calendar-decade-panel-next-decade-btn,
    .ant-calendar-decade-panel-next-year-btn {
      right: 44px;
    }

    .ant-calendar-decade-panel-prev-century-btn, .ant-calendar-decade-panel-prev-decade-btn,
    .ant-calendar-decade-panel-prev-year-btn, .ant-calendar-decade-panel-next-century-btn,
    .ant-calendar-decade-panel-next-decade-btn, .ant-calendar-decade-panel-next-year-btn {
      line-height: 64px;
      font-size: 24px;
      color: #0a0606;
    }

    .ant-calendar-decade-panel-century {
      line-height: 70px;
    }
  }

  .ant-calendar-body {
    padding: 0px 0px 16px 0px;

    .ant-calendar-cell {
      padding: 0px;
    }

    .ant-calendar-selected-day {
      .ant-calendar-date {
        background-color: #0a0606;
        color: #ffffff;
      }
    }

    .ant-calendar-date:hover {
      background: @light-gray;
    }

    .ant-calendar-today {
      .ant-calendar-date {
        background-color: @blue;
      }
    }

    .ant-calendar-date {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border: 0px;
    }

    .ant-calendar-column-header {
      color: #8d8d8d;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .ant-calendar-date-panel {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 400px;
    padding: 0px 32px;
    background: #ffffff;

    .ant-calendar-footer {
      border-top: 1px solid #e0e0e0;
      padding: 15px 0px;

      .ant-calendar-today-btn {
        display: none;
      }

      .ant-calendar-footer-extra + .ant-calendar-footer-btn {
        border-top: 0px;
      }

      .ant-calendar-footer-btn {
        border-top: 0px;
      }
    }
  }

  .v-picker-extra-footer {
    .v-btn-wrapper {
      &+ .v-btn-wrapper {
        margin-left: 16px;
      }
    }
    .v-range-picker-reset {
      float: right;
      line-height: 48px;
      color: @blue;
      cursor: pointer;

      &.hidden {
        visibility: hidden;
      }
    }
  }

}

.v-picker-wrapper {
  height: 48px;
}
