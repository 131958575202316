@import '../customVars';
@import '../colors';

.v-range-picker-alt {
  display: inline;

  &.open {
    //.v-btn-wrapper {
    //  .ant-btn {
    //    background-color: @black;
    //    border-color: @black;
    //    color: @white;
    //  }
    //}
  }

  .v-date-picker-alt-content {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 784px;
    height: 480px;
    box-shadow: 0 8px 12px 0 rgba(10, 6, 6, 0.1);
    background-color: @white;
    padding: 16px 32px;
  }

  .v-date-picker-alt-head {
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
  }

  .v-date-picker-arrow-select {
    display: flex;

    .month-text, .year-text {
      color: @black;
      text-align: center;
      font-weight: 500;
      line-height: 1.23;
    }

    .month-text {
      width: 225px;
    }

    .year-text {
      width: 100px;
    }
  }

  .ant-calendar-prev-month-btn,
  .ant-calendar-next-month-btn {
    color: @black;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .ant-calendar-prev-month-btn {
    &::after {
      content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik0xNC40MTQgMTZsNi4yOTMgNi4yOTNhMSAxIDAgMCAxLTEuNDE0IDEuNDE0bC03LTdhMSAxIDAgMCAxIDAtMS40MTRsNy03YTEgMSAwIDAgMSAxLjQxNCAxLjQxNEwxNC40MTQgMTZ6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTggLTgpIj48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIGZpbGw9IiMzMzMiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48ZyBmaWxsPSIjMEEwNjA2IiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDMydjMySDB6Ii8+PC9nPjwvZz48L3N2Zz4=');
    }
  }
  .ant-calendar-next-month-btn {
    &::after {
      content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik0xOS43MDcgMTUuMjkzYTEgMSAwIDAgMSAwIDEuNDE0bC03IDdhMSAxIDAgMCAxLTEuNDE0LTEuNDE0TDE3LjU4NiAxNmwtNi4yOTMtNi4yOTNhMSAxIDAgMSAxIDEuNDE0LTEuNDE0bDcgN3oiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOCAtOCkiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iIzMzMyIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPjxnIGZpbGw9IiMwQTA2MDYiIG1hc2s9InVybCgjYikiPjxwYXRoIGQ9Ik0wIDBoMzJ2MzJIMHoiLz48L2c+PC9nPjwvc3ZnPg==');
    }
  }

  .v-date-picker-alt-body {
    display: flex;
    justify-content: space-between;

    .v-date-picker-calendar {
      height: 336px;
    }
  }

  table {
    th,
    td {
      vertical-align: middle;
      text-align: center;
    }
  }

  thead {
    th {
      width: 32px;
      height: 48px;
      font-size: 11px;
      font-weight: 500;
      line-height: 1.45;
      text-align: center;
      color: @gray;
    }
  }

  tbody {
    td {
      width: 48px;
      height: 48px;
      cursor: pointer;
      font-size: 13px;
      line-height: 1.23;
      text-align: center;
      &.now-date-active {
        color: white;
        background-color: @blue;
      }
      &.selected-date {
        color: white;
        background-color: @black;
      }
      &.backlight-active {
        color: white;
        background-color: @black;
      }
      &.disabled-date {
        color: #d2d2d2;
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        background-color: @gray04;
      }
      &.empty-cell {
        cursor: auto;
        &:hover {
          background-color: inherit;
        }
      }
    }
  }

  .v-date-picker-alt-divider {
    width: 720px;
    height: 1px;
    border-radius: 1px;
    background-color: @secondary-color;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .v-date-picker-alt-footer {
    .footer-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-side-buttons {
        .ant-btn {
          margin-right: 16px;
        }
        .v-date-picker-alt-cancel-btn {
          box-shadow: 0 2px 16px 0 rgb(224, 224, 224), 0 8px 12px 0 rgba(10, 6, 6, 0.1);
          color: @black;
          border: none;
        }
      }
      .right-side-buttons {
        .reset-button {
          cursor: pointer;
          font-weight: 500;
          line-height: 1.23;
          text-align: right;
          color: @we-green;
        }
      }
    }
  }
}
