@import "../customVars";
@import "../colors";

@headerHeight: 142px;

.v-side-panel-mask {
  position: fixed;
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 6, 6, 1);
  z-index: 500;
}

.v-side-panel-mask.v-side-panel-open {
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-name: maskFadeIn;
}

.v-side-panel-mask.v-side-panel-close {
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-name: maskFadeOut;
}

@keyframes maskFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes maskFadeOut {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}

.v-side-panel {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  max-width: calc(100vw - 248px);
  min-width: calc(100vw - 248px);
  background: #fff;
  z-index: 550;
  font-family: Montserrat, sans-serif;
  .v-side-panel-header,
  .v-side-panel-body {
    position: relative;
    padding-left: 32px;
  }
  .v-side-panel-body {
    overflow: auto;
    height: calc(100vh - @headerHeight);
  }
  .v-side-panel-header {
    position: relative;
    height: @headerHeight;
    background: @white;

    ::selection {
      background: @black;
    }
    ::-moz-selection {
      background: @black;
    }

    .we-title-container {
      display: flex;
      justify-content: center;
      padding-top: 36px;

      .v-icon-wrapper {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        font-size: 32px;
        text-align: center;
        position: relative;
        line-height: 0;
        .v-icon {
          width: 28px;
          height: 28px;
          color: gray;
          svg {
            width: 28px;
            height: 28px;
          }
        }
      }

      .we-sidepanel-title {
        h1 {
          font-size: 24px;
          line-height: 24px;
          font-weight: 700;
          margin-bottom: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .we-sidepanel-subtitle {
      text-align: center;
      margin-top: 12px;

      p {
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 16px;
        color: #8D8D8D;
      }
    }

    .we-sidepanel-header-divider {
      position: absolute;
      height: 2px;
      width: calc(100% - 64px);
      bottom: 0;
      background: #F2F7F5;
    }

    h1, h2, h3, h4, p, span, .v-icon {
      color: @black;
    }
    .v-side-panel-close {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 32px;
      right: 32px;
      text-align: center;
      cursor: pointer;

      .v-modal-close-x {
        font-size: 16px;
      }

      .v-icon {
        color: @black04;
        transition: color 150ms;
      }

      &:hover {
        .v-icon {
          color: @black;
        }
      }
    }
  }
}

.v-side-panel.v-side-panel-open {
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-name: slideFadeIn;
}

.v-side-panel.v-side-panel-close {
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-name: slideFadeOut;
}

@keyframes slideFadeIn {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@keyframes slideFadeOut {
  0% {
    right: 0;
  }
  100% {
    right: -100;
  }
}



.ant-modal-wrap {
  .ant-modal {
    &.v-side-panel {
      top: 0;
      padding: 0;
      height: 100vh;
      margin-right: 0;
      margin-left: auto;

      .v-modal-close {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 32px;
        left: 32px;
        text-align: center;
        cursor: pointer;

        .v-modal-close-x {
          font-size: 16px;

          .v-icon {

          }
        }
      }

      &.v-side-panel-black {
        .ant-modal-content {
          .ant-modal-header {

          }
        }
      }

      .ant-modal-content {
        height: 100%;

        .ant-modal-close {
          color: @white;
          left: 0;
          right: auto;

          .ant-modal-close-x {
            font-size: 20px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            margin-left: 32px;
            margin-top: 32px;
          }
        }

        .ant-modal-header {
          height: 208px;
          padding-top: 112px;
          padding-left: 64px;
          background-color: @black;
          border-bottom: none;

          ::selection {
            background: @white;
            color: @black;
          }

          .ant-modal-title {
            font-size: 32px;
            color: @white;

            .v-icon-wrapper {
              float: left;
              width: 96px;
              height: 96px;
              font-size: 64px;
              text-align: center;

              .v-icon {

              }
            }
            .v-titles-wrapper {
              float: left;
              padding-left: 16px;
              padding-top: 8px;
              width: calc(~"100% - 96px");

              h1 {
                font-size: 32px;
                line-height: 32px;
                margin-bottom: 8px;
              }
              p {
                font-size: 13px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
              }
            }
            h1, h2, h3, h4, h5 {
              color: @white;
            }
          }
        }

        .ant-modal-body {
          padding: 0 0 0 80px;
          height: calc(~"100% - 208px");
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}

@media (min-width: 1300px) {
  .v-side-panel {
    min-width: calc(50vw + 406px);
    max-width: calc(50vw + 406px);
  }
}

@media (max-width: 768px) {
  .v-side-panel {
    min-width: 100%;
    max-width: 100%;
  }
}

.v-side-panel-sticky {
  overflow: auto;

  .v-side-panel-body {
    overflow: unset;

    > .ant-tabs.ant-tabs-card {
      margin-top: -24px;

      .ant-tabs-bar {
        position: sticky;
        top: 16px;
        z-index: 1;
      }
    }
  }
}
