@import "../customVars";

.v-input.ant-input {
  font-size: 13px;
  line-height: 16px;
  height: 48px;
  box-shadow: @common-shadow;
  padding: 15px 36px 15px 20px;
  border: 1px solid #fff;
  color: #0a0606;
  transition: border-color 300ms linear;
  width: 100%;
  text-overflow: ellipsis;

  &::placeholder {
    font-size: 11px;
  }

  &:focus {
    border-color: @primary-color;
    box-shadow: @common-shadow;
  }

  &[readonly] {
    border: none;
  }

  &:disabled {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #8d8d8d;

    &::placeholder {
      color: #8d8d8d;
    }
  }
}
textarea.v-input.ant-input {
  height: auto;
  resize: none;
}
.v-input-wrapper {
  display: block;
  position: relative;
  z-index: 10;

  &.pin-to-top {
    position: sticky;
    top: 0;
  }

  &.focused, &.has-value {
    .ant-input-wrapper {
      .ant-input-group-addon {
        width: 0;

        &+ .ant-input.v-input {
          padding-left: 20px;
          transition: padding-left 300ms;
        }

        .v-icon {
          opacity: 0;
        }
      }
    }
  }

  &.has-value {
    .v-clear-input {
      display: inline-block;
    }
    .v-input-spinner {
      right: 48px;
    }
  }

 .ant-input-wrapper {
   box-shadow: @common-shadow;

    .ant-input-group-addon {
      position: absolute;
      background-color: @white;
      top: 1px;
      left: 1px;
      z-index: 10;
      width: 44px;
      line-height: 44px;
      border: 1px solid transparent;
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      color: @gray;
      overflow: hidden;
      text-align: center;

      &+ .ant-input.v-input {
        padding-left: 48px;
      }

      .v-icon {
        visibility: visible;
        opacity: 1;
        transition: opacity 10ms;
        transition-delay: 10ms;
      }
    }

    .ant-input.v-input {
      box-shadow: none;
    }
  }

  .v-clear-input {
    display: none;
    position: absolute;
    z-index: 9;
    right: 16px;
    top: 1px;
    padding: 8px;
    cursor: pointer;
    text-align: center;

    .v-icon {
      display: block;
      width: 16px;
      height: 16px;
      line-height: 14px;
      font-size: 16px;
    }
  }

  .v-input-spinner {
    position: absolute;
    z-index: 10;
    right: 14px;
    top: 15px;
  }
}

.v-input.ant-input-number {
  font-size: 13px;
  line-height: 16px;
  height: 48px;
  box-shadow: @common-shadow;
  padding: 15px 20px;
  border: 1px solid #fff;
  color: #0a0606;
  transition: border-color 300ms linear;
  width: 100%;

  .ant-input-number-input {
    &::placeholder {
      font-size: 11px;
    }
  }

  .ant-input-number-input-wrap {
    margin-top: -7px;
    margin-left: -11px;
  }

  &:disabled {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #8d8d8d;

    &::placeholder {
      color: #8d8d8d;
    }
  }
}

.v-input.ant-input-number-focused {
  border-color: @primary-color;
  box-shadow: @common-shadow;
}

.v-input {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.v-form.ant-form {
  .ant-form-item {
    &-control-wrapper {
      .ant-form-item-control {
        line-height: unset;
        .v-input {
        }

        .v-picker-wrapper {
          .v-btn-wrapper {
            width: 100%;
            .ant-btn {
              width: 100%;
              text-align: left;
            }
          }
        }

        &.has-error {
          .v-input {
            border-color: @error-color;
          }
          .ant-btn {
            border: 1px solid @error-color;
          }
        }

        &.has-warning {
          .v-input {
            border-color: @warning-color;
          }
          .ant-btn {
            border: 1px solid @warning-color;
          }
        }

        &.has-success {
          .v-input {
            border-color: @success-color;
          }
          .ant-btn {
            border: 1px solid @success-color;
          }
        }
      }
    }
  }

  .ant-form-explain {
    font-size: 11px;
    height: 19.5px;
    padding-top: 2px;
  }

  .has-error, .has-warning {
    .ant-form-explain, .ant-form-split {
      color: @gray;
    }
  }
}
