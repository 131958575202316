.v-simple-table {
  .ant-table {
    .ant-table-body {
      .ant-table-row {
        td {
          border-bottom-color: transparent;
        }
        &:hover {
           & > td {
             background-color: inherit;
           }
        }
      }
    }
  }
}
